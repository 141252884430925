import { Suspense, lazy, useEffect } from 'react';
import Soccerball from '../assets/Soccerball.svg';

const Root = lazy(() => import('./root'));

export function App() {
	useEffect(() => {
		const el = document.getElementById('main-loader');
		if (el) {
			el.remove();
		}
	}, []);

	return (
		<Suspense fallback={<FallbackComponent />}>
			<Root />
		</Suspense>
	);
}

function FallbackComponent() {
	return (
		<div
			className="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-4 transition-colors bg-base-200"
			style={{
				background: 'radial-gradient(oklch(var(--b1)),oklch(var(--b1)), oklch(var(--p)/0.3))',
			}}
		>
			<div className="animate-spin-ball">
				<img src={Soccerball} style={{ width: '10vw', height: '10vh' }} alt="loader" />
			</div>
			<div className="flex gap-1">
				<span className="text-2xl font-light">Loading...</span>
			</div>
		</div>
	);
}
